.achievements {
	position: fixed;
	top: 10%;
	left: 1%;
	display: flex;
}

.dailyReward {
	position: fixed;
	top: 25%;
	left: 1%;
}

.summary {
	position: fixed;
	top: 60%;
	left: 1%;
}

.purchases {
	position: fixed;
	top: 20%;
	right: 1%;
}

.advices {
	position: fixed;
	top: 70%;
	right: 1%;
}
