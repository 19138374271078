@import 'theme/index.scss';

.overlay {
	position: absolute;
	z-index: 9;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background-color: rgba($black, 0.7);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color: $white;
	border-radius: 2px;
	height: 300px;
	width: 300px;

	:global(.ant-tabs) {
		width: 100%;
		color: $white;

		:global(.ant-tabs-nav-wrap) {
			justify-content: center;
		}
	}

	:global(.ant-tabs-tab) {
		text-transform: uppercase;
		font-weight: 500;
	}

	:global(.ant-form) {
		padding: 0 30px;
	}

	:global(.ant-checkbox-wrapper) {
		color: $white;
	}
}
