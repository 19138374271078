$white: #fff;
$black: #000;
$yellow: #d2a967;
$goldText: #f8e250;
$green: #5ebd00;
$purple: #7a34dd;
$brown: #594500;
$blue: #0d1b64;
$azure: rgba(49, 173, 187, 1);
$lightBlue: rgba(55, 71, 118, 1);
$beige: #f7e2ac;
$red: #d64222;
$gray: #8e7d65;
$wheat: #ac8e5c;

$mainFont: cursive;
