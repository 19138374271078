.container {
	background: url('../../images/login-background.png') no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	//padding-top: 50px;
}

.overlay {
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 20px;
}

.title {
	font-size: 30px;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	padding: 80px 40px;
}

.ordersTitle {
	font-size: 24px;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	padding-bottom: 20px;
	border-bottom: 1px white solid;
}

.orders {
	padding: 10px 10px 20px;
	width: 100%;
	border-top: 1px white solid;
	border-bottom: 1px white solid;
}

.order {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.orderDate {
	color: white;
	font-size: 26px;
	font-weight: bold;
	padding: 20px 0 5px 20px;
}

.content {
	display: flex;
	justify-content: space-between;
}

.details {
	color: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-basis: 25%;

	.detailsTitle {
		font-size: 24px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	.name,
	.location {
		font-size: 20px;
	}
}

.history {
	flex-basis: 60%;
	padding-left: 40px;

	span {
		color: white;
		font-size: 26px;
		font-weight: bold;
	}
}
