@import 'theme/index.scss';

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.achievementsTotal {
}

.achievementsList {
	padding: 5px;
	flex: 1;
	display: flex;
	flex-direction: column;
	//overflow-y: scroll; !todo mask
}

.achievementsFooter {
	background-color: $lightBlue;
	flex-basis: 15%;
	display: flex;
	flex-direction: column;
	border-radius: 0 0 15px 15px;
	box-shadow: 0 1px 0 rgba($black, 1);
	justify-content: center;
	align-items: end;
}

.footerBtn {
	background-color: $green;
	height: 40%;
	width: 50%;
	border-radius: 8px;
	border: none;
	box-shadow: 0 5px 0 rgba(37, 84, 19, 255), 0 7px 0 rgba(197, 186, 148, 255),
		0 7px 0 rgba(197, 186, 148, 255);
	font-size: 24px;
	color: $white;
	font-family: $mainFont;
	font-weight: bold;
	margin-right: 20px;

	&:active {
		box-shadow: 0 2px 0 rgba(197, 186, 148, 255),
			0 2px 0 rgba(197, 186, 148, 255);
		transform: translateY(3px);
		transition: ease 0.1s;
	}
}
