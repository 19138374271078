.container {
	background: url('../../images/login-background.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: rgba($color: #fff, $alpha: 0.5);
		position: absolute;
		left: 0;
	}
}
