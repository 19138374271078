@import 'theme/index.scss';

.goal {
	background-color: rgba($black, 0.25);
	flex-basis: 33%;
	padding: 5px;
	margin: 5px;
	border-radius: 10px;
	font-family: $mainFont;
}

.header {
	display: flex;
	justify-content: space-between;
	color: $goldText;
	font-style: italic;
	font-weight: bold;
	font-size: 20px;
}

.title {
	font-size: 24px;
}

.score {
}

.description {
	color: $white;
	font-weight: bold;
	font-size: 14px;
}

.crown {
	width: 28px;
	height: 28px;
}
