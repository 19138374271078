@import 'theme/index.scss';

.overlay {
	position: fixed;
	z-index: 9;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba($black, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	color: $white;

	.modal {
		background-color: rgba($black, 0.3);
		border-radius: 2px;
		position: relative;
		width: 100%;
		height: 100%;
	}

	.close {
		width: 60px;
		font-family: $mainFont;
		font-weight: bold;
		position: absolute;
		right: 8px;
		top: 4px;
		font-size: 36px;
		cursor: pointer;
		border-radius: 10px;
		border: none;
		background-color: $red;
		box-shadow: 0 5px 0 #932513, 1px 7px 0 rgba(197, 186, 148, 255),
			-1px 7px 0 rgba(197, 186, 148, 255);

		&:active {
			box-shadow: 1px 2px 0 rgba(197, 186, 148, 255),
				-1px 2px 0 rgba(197, 186, 148, 255);
			transform: translateY(10px);
			transition: ease 0.1s;
		}
	}
}
