@import 'theme/index.scss';

.header {
	width: 100%;
	height: 70px;
	background-color: $beige;
	padding: 5px 0;
}

.title {
	font-size: 24px;
	font-family: $mainFont;
	border-radius: 10px;
	background-color: $wheat;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
