.container {
	position: fixed;
	width: 300px;
	height: 400px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: gray;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
}

.title {
	font-size: 20px;
	font-weight: bold;
}

.order {
	position: relative;
	bottom: 75%;
	right: 55%;

	div {
		position: absolute;
		transform: scale(0.5);
		width: 320px;
	}
}

.buttons {
	width: 100%;
	display: flex;
	justify-content: space-evenly;

	button {
		border: 1px #000 solid;
		color: black;
		font-size: 18px;
	}
}
