@import 'theme/index.scss';

.container {
	z-index: 9;
	width: 100%;
	height: 100%;
	background-image: url('../../images/modal-bg.jpg');
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 60%;
	height: 80%;
	border-radius: 5px;
	border: #ebc67f 10px solid;
	border-bottom: #ebc67f 30px solid;
}

.title {
	font-family: $mainFont;
	font-weight: bold;
	font-size: 54px;
	color: $goldText;
}

.subtitle {
	font-family: $mainFont;
	font-weight: bold;
	font-size: 24px;
	color: $white;
}

.rewardsList {
	flex: 1;
	display: flex;
	width: 100%;
}

.rewards {
	flex-basis: 75%;
	display: flex;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;

	//custom scroll
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar {
		width: 12px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: $brown;
	}
}

.lastReward {
	flex-basis: 25%;
	display: flex;
}
