@import 'theme/index.scss';

.achievement {
	flex-basis: 25%;
	display: flex;
	align-items: flex-start;
	background: url('../../images/achievements-bg.png') no-repeat;
	background-size: cover;
	background-position-y: -10px;
	padding: 5px;
	margin-bottom: 10px;
}

.header {
	flex: 1;
	padding: 0 5px;
}

.image {
	width: 64px;
}

.title {
	font-size: 24px;
	color: $white;
	font-family: $mainFont;
	font-weight: bold;
	font-style: italic;
}

.description {
	color: $brown;
	font-size: 14px;
	font-family: $mainFont;
	font-weight: bold;
}

.progress {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-basis: 20%;
}

.progressBar {
	width: 100%;
}

.score {
	color: $brown;
	font-size: 18px;
	font-family: $mainFont;
	font-weight: bold;
}

.reward {
	flex: 1;
	align-self: end;
	color: rgba($brown, 0.5);
	font-family: $mainFont;
	font-weight: bold;

	span {
		color: $white;
		font-size: 18px;
	}
}

.crown {
	width: 28px;
	height: 28px;
}
