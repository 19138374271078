@import 'theme/index.scss';

.container {
	width: 30%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	border-radius: 6px;
	color: $white;
	padding: 10px;
	background: rgba($black, $alpha: 0.7);
	margin-top: 30px;
	height: 500px;

	&:last-child {
		button {
			background: $green;
		}
	}

	.title {
		color: $goldText;
		font-size: 36px;
	}

	img {
		width: 200px;
	}

	button {
		width: 100%;
		background: $purple;
		height: 100px;
		border-radius: 4px;
		font-size: 36px;
		font-weight: 700;
		cursor: pointer;
	}
}
