@import 'theme/index.scss';

.upgradeList {
	width: 100%;
	height: 100%;
	overflow-y: scroll;

	//custom scroll
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar {
		width: 12px;
		background-color: #f5f5f5;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba($black, 0.3);
		background-color: $brown;
	}
}

.lockedUpgrade {
	display: flex;
	justify-content: center;
	border-radius: 10px;
	height: 150px;
	background-color: rgba($black, 0.5);
	padding: 10px;
	margin: 5px;
	align-items: center;
	color: $goldText;
	font-family: $mainFont;
	font-weight: bold;
	font-size: 24px;
}
