@import 'theme/index.scss';

.order {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.orderItem {
	width: 250px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	border-radius: 6px;
	color: $white;
	padding: 10px;
	background: rgba($black, $alpha: 0.7);
	margin-top: 30px;
	height: 360px;

	.orderItemTitle {
		color: $goldText;
		font-size: 24px;
	}

	img {
		width: 150px;
	}

	button {
		width: 100%;
		background: $purple;
		height: 70px;
		border-radius: 4px;
		font-size: 36px;
		font-weight: 700;
	}
}

.title {
	color: white;
	font-weight: bold;
	font-size: 30px;
	margin-left: 40px;
}

.cost {
	color: white;
	font-weight: bold;
	font-size: 26px;
	flex-basis: 50%;
	text-align: end;
}
