.container {
	z-index: 9;
	width: 100%;
	height: 100%;
	background-image: url('../../images/modal-bg.jpg');
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}
