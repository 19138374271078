.container {
	background: url('../../images/login-background.png') no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding-top: 50px;
}

.overlay {
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 20px;
}

.title {
	text-transform: uppercase;
	color: white;
	font-size: 26px;
	font-weight: bold;
	padding: 20px 0 5px 20px;
	border-bottom: 1px white solid;
	width: 100%;
}

.orders {
	padding: 10px 10px 20px;
	width: 100%;
	border-bottom: 1px white solid;
}

.total {
	padding: 10px;
	display: flex;
	justify-content: space-between;
	color: white;
	font-size: 28px;
	font-weight: bold;
	border-bottom: 1px white solid;
}

.checkout {
	display: flex;
	justify-content: end;
	padding-top: 10px;

	button {
		color: black;
		font-size: 20px;
		font-weight: bold;
		width: 250px;
		padding: 10px;
	}
}
