@import 'theme/index.scss';

.wrapper {
	align-self: end;
	min-width: 31%;
	height: 80%;
	display: flex;
	flex-direction: column;
	margin: 10px;
	flex: 1;
	position: relative;
}
.locked {
	position: relative;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: rgba($black, 0.6);
		position: absolute;
		top: 0;
	}
}

.reward {
	background-color: $brown;
	width: 80%;
	height: 50%;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.lastReward {
	height: 90%;
}

.countRewards {
	color: $white;
	font-size: 28px;
	font-family: $mainFont;
	font-weight: bold;
}

.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $beige;
}

.title {
	font-size: 24px;
	font-family: $mainFont;
	font-weight: bold;
	color: $brown;
}

.crown {
	width: 56px;
	height: 56px;
}

.chest {
	width: 95px;
	height: 80px;
	position: relative;
	top: -40px;
}

.watchVideo {
	width: 100%;
	height: 70px;
	background: #ead547;
	position: relative;
	color: $white;
	font-size: 20px;
	font-family: $mainFont;
	font-weight: bold;
	border: none;
	border-radius: 0 0 5px 5px;
	cursor: pointer;
}

.lockedChest {
	opacity: 0.6;
}

.lockedButton {
	background-color: $gray;
}
