@import 'theme/index.scss';

.container,
.main,
.content {
	display: flex;
}

.container {
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.8477984943977591) 0%,
		rgba(89, 69, 0, 0.8505996148459384) 100%
	);
	min-height: 100vh;
	flex-direction: column;
}

.main {
	flex-grow: 1;
}

.content {
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	flex-grow: 1;
}

.title {
	color: $goldText;
	font-size: calc(10px + 3vmin);
}
