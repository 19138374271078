@import 'theme/index.scss';

.tabsContainer {
	width: 60%;
	height: 80%;
	display: flex;
	flex-direction: column;
}

.tabsHeader {
	display: flex;
	margin-left: 25px;
}

.tab {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 115px;
	height: 45px;
	border: none;
	cursor: pointer;
	background-color: $yellow;
	color: $brown;
	font-family: $mainFont;
	font-style: italic;
	font-size: 24px;
	font-weight: bold;
	margin: 0 5px;
}

.activeTab {
	background-color: $beige;
}

.tabContent {
	flex: 1;
	background-color: $gray;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: #f7e2ac 10px solid;
	border-bottom: #f7e2ac 30px solid;
	border-radius: 5px;
}

.tabContentHeader {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 40px;
	width: 100%;
	padding: 0 30px;
	background-color: $beige;
	color: $wheat;
	font-style: italic;
	font-family: $mainFont;
	font-weight: bold;
	font-size: 18px;
}

.tabContentMain {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	width: 50%;
	color: $beige;
	font-family: $mainFont;
	font-weight: bold;
}
