.button {
	background-color: rgba(88, 95, 152, 255);
	width: 6rem;
	height: 6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	box-shadow: 0 10px 0 rgba(27, 36, 76, 255),
		2px 14px 0 rgba(197, 186, 148, 255), -2px 14px 0 rgba(197, 186, 148, 255);

	&:active {
		box-shadow: 2px 4px 0 rgba(197, 186, 148, 255),
			-2px 4px 0 rgba(197, 186, 148, 255);
		transform: translateY(10px);
		transition: ease 0.1s;
	}

	.icon {
		max-width: 95%;
	}
}
