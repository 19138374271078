@import 'theme/index.scss';

.tabsFooter {
	background-color: $lightBlue;
	flex-basis: 15%;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 0 0 15px 15px;
	box-shadow: 0 1px 0 rgba($black, 1);
	color: $white;
	font-weight: bold;
	font-family: $mainFont;

	.footerBtn {
		background-color: $azure;
		height: 40%;
		width: 50%;
		border-radius: 8px;
		border: none;
		box-shadow: 0 5px 0 rgba(36, 103, 128, 255),
			0 7px 0 rgba(197, 186, 148, 255), 0 7px 0 rgba(197, 186, 148, 255);
		font-size: 24px;

		&:active {
			box-shadow: 0 2px 0 rgba(197, 186, 148, 255),
				0 2px 0 rgba(197, 186, 148, 255);
			transform: translateY(3px);
			transition: ease 0.1s;
		}
	}
}

.goalsList {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
}

.crown {
	width: 28px;
	height: 28px;
}
