:root {
	--link-color: #e7e7e7;
	--border-color: #616161;
}

.container {
	position: fixed;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	font-size: calc(10px + 1vmin);
	background-color: black;
	border-bottom: 1px solid var(--border-color);
}

.cartLink {
	display: flex;
	align-items: center;
	margin-left: 20px;
	color: var(--link-color);
	text-decoration: none;
}

.nickName {
	color: white;
	font-weight: bold;
	font-size: 16px;
}

.options {
	display: flex;
}
