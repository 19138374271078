@import 'theme/index.scss';

.upgradeContainer {
	display: flex;
	justify-content: space-between;
	border-radius: 10px;
	height: 150px;
	background-color: rgba($black, 0.5);
	padding: 10px;
	margin: 5px;
	align-items: end;
}

.upgradeImg {
	height: 80%;
}

.upgradeInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;

	.title {
		color: $goldText;
		font-family: $mainFont;
		font-weight: bold;
		font-size: 18px;
	}

	.level {
		font-family: $mainFont;
		font-style: italic;
		font-size: 16px;
		color: $gray;
	}

	.time {
		font-family: $mainFont;
		font-weight: bold;
		font-size: 22px;
		color: $white;
		display: flex;
		align-items: center;
	}
}

.upgradeBtn {
	max-width: 250px;
	flex-basis: 40%;
	background-color: $green;
	height: 40%;
	border-radius: 8px;
	border: none;
	box-shadow: 0 5px 0 rgba(37, 84, 19, 255), 0 7px 0 rgba(197, 186, 148, 255),
		0 7px 0 rgba(197, 186, 148, 255);
	font-size: 24px;
	color: $white;
	font-family: $mainFont;
	font-weight: bold;
	margin-right: 20px;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	div {
		display: flex;
		align-items: center;
	}

	&:active {
		box-shadow: 0 2px 0 rgba(197, 186, 148, 255),
			0 2px 0 rgba(197, 186, 148, 255);
		transform: translateY(3px);
		transition: ease 0.1s;
	}
}

.icon {
	width: 20px;
	height: 20px;
	margin-right: 2px;
}
