@import 'theme/index.scss';

.tabsContainer {
	position: relative;
	width: 700px;
	height: 600px;
	top: 20px;
	border-radius: 15px;
	border: $beige 3px solid;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow: hidden;
}

.tabsHeader {
	display: flex;
	justify-content: space-between;
	background-color: $lightBlue;
	flex-basis: 10%;
}

.tab {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 15px 15px 0 0;
	border-bottom: none;
	cursor: pointer;
	background-color: $blue;
	color: $lightBlue;
	font-family: $mainFont;
	font-style: italic;
	font-size: 24px;
	font-weight: bold;
}

.tab:first-child {
	border-right: none;

	&:active {
		border-right: rgba(226, 211, 144, 255) 3px solid;
		border-top: rgba(226, 211, 144, 255) 3px solid;
	}
}

.tab:last-child {
	border-left: none;

	&:active {
		border-left: rgba(226, 211, 144, 255) 3px solid;
		border-top: rgba(226, 211, 144, 255) 3px solid;
	}
}

.activeTab {
	background-color: $lightBlue;
	color: $white;
}

.tabContent {
	flex: 1;
	background-color: rgba($black, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
