@import 'theme/index.scss';

:root {
	--top: #000211;
	--bottom: #020522;
	--link-color: #e7e7e7;
	--title-color: #b6b6b6;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	background: linear-gradient(var(--top), var(--bottom));
}

.title {
	color: var(--title-color);
	padding: 20px;
	margin-bottom: 20px;
}

.crown,
.resource {
	color: var(--link-color);
	height: 50px;
	width: 100%;
	margin-bottom: 20px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	cursor: pointer;
}

.crown {
	background: $blue;
	box-shadow: -1px 8px 17px -3px rgba(13, 27, 100, 0.75);
}

.resource {
	background: $purple;
	box-shadow: -1px 8px 17px -3px rgba(122, 52, 221, 0.75);
}
